var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full lg:px-0 xl:px-0 px-7"},[_c('div',[_vm._v("Step 1/4")]),_c('div',{staticClass:"text-2xl font-bold leading-loose mt-2"},[_vm._v("Basic Info")]),_c('div',{staticClass:"text-primary-color-gray"},[_vm._v(" Fill in your details below, let’s get to know you better. ")]),_c('hr',{staticClass:"my-4 border-t border-border-color lg:block xl:block hidden"}),_c('form',{staticClass:"mt-10"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"lg:grid xl:grid block grid-cols-2 gap-8"},[_c('custom-input',{attrs:{"dataField":{
                        label: 'First name',
                        field: 'firstName',
                        placeholder: 'Enter first name(special characters are not allowed)',
                    }},on:{"input-value":_vm.assignValue}}),_c('custom-input',{attrs:{"dataField":{
                        label: 'Last name',
                        field: 'lastName',
                        placeholder: 'Enter last name',
                    }},on:{"input-value":_vm.assignValue}}),_c('custom-input',{attrs:{"dataField":{
                        label: 'Phone number',
                        field: 'phone',
                        placeholder: 'Enter phone number',
                    }},on:{"input-value":_vm.assignValue}}),_c('custom-input',{attrs:{"dataField":{
                        label: 'Email address',
                        field: 'email',
                        placeholder: 'Enter email address',
                    }},on:{"input-value":_vm.assignValue}})],1),_c('custom-select',{staticClass:"my-8",attrs:{"dataField":{
                    label: 'How did you hear about us?',
                    field: 'survey',
                    placeholder: 'Select an option',
                }},on:{"input-value":_vm.assignValue}}),_c('div',{staticClass:"my-8 flex items-center"},[(!_vm.checked)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"w-4 mr-2",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,null)>-1:(_vm.checked)},on:{"change":function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}}}}):_c('img',{staticClass:"w-4 mr-2",attrs:{"src":require("@/assets/images/checked-box.svg"),"alt":"checkbox"},on:{"click":function($event){_vm.checked = false}}}),_c('span',{staticClass:"text-sm"},[_vm._v("Send me marketing and promotional content.")])])],1),_c('next-button',{attrs:{"submitting":_vm.submitting},on:{"next":_vm.nextStep}},[_c('span',{attrs:{"slot":"button-text"},slot:"button-text"},[_vm._v("Next step")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }