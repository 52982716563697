<template>
    <main class="lg:block xl:block flex flex-row justify-between">
        <div
            v-for="(item, index) in navItems"
            :key="index + 'desktop'"
            class="lg:flex xl:flex hidden relative lg:mb-16 xl:mb-16 mb-0 ml-auto"
        >
            <div class="text-right w-full lg:pr-12 xl:pr-12 pr-0 lg:visible xl:visible invisible">
                <h1 class="text-lg font-bold leading-5 capitalize">{{ item.heading }}</h1>
                <div class="text-primary-color-gray">{{ item.content }}</div>
            </div>
            <div
                :data-user-data="item.heading"
                class="w-12 h-12 flex items-center justify-center rounded-full bg-icons-bg absolute lg:-right-6 xl:-right-6 -right-0 lg:top-0 xl:top-0 -top-6"
            >
                <img :src="item.icon" :alt="item.content" />
            </div>
            <div class="lg:inline-block xl:inline-flex hidden">
                <div
                    class="absolute w-12 h-12 flex items-center justify-center"
                    style="right: -5.62rem"
                >
                    <div
                        class="w-2.5 h-2.5 bg-white border border-icons-bg rounded-full flex items-center justify-center"
                    >
                        <div
                            :data-user-data="item.heading"
                            class="w-1.5 h-1.5 bg-icons-bg rounded-full"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-for="(item, index) in navItems"
            :key="index + 'mobile'"
            :data-user-data="item.heading"
            class="w-12 h-12 lg:hidden xl:hidden flex items-center justify-center rounded-full bg-icons-bg -mt-6"
        >
            <img :src="item.icon" :alt="item.content" />
        </div>
    </main>
</template>

<script>
export default {
    name: 'NavItem',
    data() {
        return {
            isUserInfoActive: true,
            isAddress: false,
            isProduct: false,
            isPayment: false,
            navItems: [
                {
                    heading: 'Basic info',
                    content: 'Enter your data',
                    icon:
                        '/profile.png',
                },
                {
                    heading: 'Address',
                    content: 'Enter your address',
                    icon:
                        '/package.png',
                },
                {
                    heading: 'Products',
                    content: 'Choose a product',
                    icon:
                        '/location.png',
                },
                {
                    heading: 'Payment',
                    content: 'Enter card details',
                    icon:
                        '/card.png',
                },
            ],
        }
    },
}
</script>
